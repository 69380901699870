<!--
  - Copyright (C) 2024. Archimedes Exhibitions GmbH,
  - Saarbrücker Str. 24, Berlin, Germany
  -
  - This file contains proprietary source code and confidential
  - information. Its contents may not be disclosed or distributed to
  - third parties unless prior specific permission by Archimedes
  - Exhibitions GmbH, Berlin, Germany is obtained in writing. This applies
  - to copies made in any form and using any medium. It applies to
  - partial as well as complete copies.
  -->

<template>
  <div>
    <div v-if="userInfo && userProfile" class="wrap">
      <h1 class="mt-5 mb-3">{{ $t('ems.profile.profile') }}</h1>
      <div class="profile-info">
        <div class="row mt-2">
          <div class="col-10">
            <b-icon-person-badge
              class="float-left"
              font-scale="7"
            ></b-icon-person-badge>
            <span>{{ userInfo.preferred_username }}</span>
            <span>{{ userProfile.firstName }} {{ userProfile.lastName }}</span>
            <span>{{ userInfo.email }}</span>
            <small>{{ $t('ems.profile.userId') }}: {{ userInfo.sub }}</small>
          </div>
          <div class="col-2">
            <b-button
              class="mb-1 mr-1"
              size="sm"
              variant="dark"
              @click="onEditUser"
            >
              <b-icon-pencil></b-icon-pencil>
            </b-button>
            <b-button
              class="mb-1"
              size="sm"
              variant="dark"
              @click="onEditPassword"
            >
              <b-icon-key></b-icon-key>
            </b-button>
          </div>
        </div>
      </div>
      <b-alert class="mt-3" variant="info" show>
        {{ $t('ems.profile.deleteAccountInfo') }}
      </b-alert>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'UserProfile',
    computed: {
      user () {
        if (this.userInfo && this.userProfile) {
          return Object.assign(this.userInfo, this.userProfile)
        }
        return null
      }
    },
    watch: {
      password: function () {
        this.repeatedPassword = null
      }
    },
    data () {
      return {
        pwIsLoading: false,
        userInfo: null,
        userProfile: null,
        password: null,
        repeatedPassword: null
      }
    },
    methods: {
      loadUserInfo () {
        this.$keycloakmanager
          .loadUserInfo()
          .then((res) => {
            this.userInfo = res
          })
          .catch((err) => {
            console.debug(err)
          })
      },
      loadUserProfile () {
        this.$keycloakmanager
          .loadUserProfile()
          .then((res) => {
            this.userProfile = res
          })
          .catch((err) => {
            console.debug(err)
          })
      },
      onEditUser () {
        this.$keycloakmanager.keyCloak.login({
          action: 'UPDATE_PROFILE'
        })
      },
      onEditPassword () {
        this.$keycloakmanager.keyCloak.login({
          action: 'UPDATE_PASSWORD'
        })
      }
    },
    mounted () {
      if (this.$keycloakmanager.isConfigured) {
        this.loadUserInfo()
        this.loadUserProfile()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .wrap {
    max-width: 768px;
    margin: 0px auto;
  }

  .profile-info {
    border-radius: 6px;
    box-shadow: rgb(231 235 238) 0px 0px 0px 1px;
    padding: 20px 30px 30px 30px;

    span {
      display: block;
    }
  }
</style>
